
























































import Vue, { PropOptions } from 'vue';
import { headroom } from 'vue-headroom';
import type { Button } from '~/components/Buttons/Button.vue';

export type Header = Blok & {
  notification: Blok[];
  logo: BlokFieldAsset;
  websiteLinks: Blok[];
  webappLinks: Button[];
  bannerAlert: any;
};

export default Vue.extend({
  components: { headroom },
  props: {
    blok: {
      type: Object,
      required: true,
    } as PropOptions<Header>,
    hideBannerAlert: { type: Boolean },
  },

  data() {
    return {
      mobileMenuOpen: false,
    };
  },

  computed: {
    isMobile(): boolean {
      if (process.browser) {
        return window.matchMedia('(max-width: 1024px)').matches;
      }
      return false;
    },
  },
});
